@import "../../../assets/scss/mixin";
@import "../../../assets/scss/variables";

.cntResults {
  padding: 2rem;

  .header {
    padding: 0 0 1.5rem;

    &_title {
      @include text(1.375rem, layout-grey-90, medium);
      margin: 0;
      padding: 0 0 0.5rem;
    }
    &_link {
      @include text(0.875rem, darwined-blue-50);
      align-items: flex-end;
    }
  }

  &_table {
    padding: 2rem 0 0;
  }
}

.cntResultsSkeleton {
  padding: 2rem;
  &_title {
    @include colWidth(4);
    margin: 0 0 0.5rem;
  }
  &_link {
    @include colWidth(2);
    align-items: flex-end;
  }
  &_table {
    margin: 2rem 0 0;
  }
}
