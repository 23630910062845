@import "../../../assets/scss/avocadoVariables";
@import "../../../assets/scss/mixin";
@import "../../../assets/scss/variables";

.modalSectionEditor {
  top: 52px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: map-get($colors, layout-grey-10);
  z-index: 9999;
}

.gridInfo {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  .infoWeeks {
    height: 100%;
    padding: 0 1rem 0 0;
  }
  .sectionGrid {
    width: 100%;
    padding: 1.3rem 0.8rem 1.3rem 1rem;
    background: map-get($colors, layout-white);
  }
}

.cntAccordion {
  margin: 1rem 0 0;
  width: 100%;
  &_title {
    @include text(1rem, layout-grey-90, medium);
    margin: 0;
  }
  .cntItem {
    padding: 0.5rem 1rem;
    border-bottom: 1px solid map-get($colors, layout-grey-10);
    cursor: pointer;
    &:last-of-type {
      border-bottom: 0;
    }
    &_text {
      @include text(1rem, layout-grey-90);
      margin: 0;
      text-transform: uppercase;
      &:last-of-type {
        text-transform: initial;
      }
      &.cntItem_text__error {
        color: map-get($colors, message-negative-50);
      }
    }
  }
}

.modalHeader {
  span.modalHeader_bookingTitle {
    font-size: map-get($sizeText, 22);
    font-weight: map-get($fonts, medium);
    padding: 0;
  }
}
