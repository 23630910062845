@charset "utf-8";
@import "variables";
@import "./responsive";
@import "./customTable.scss";
@import "tabs";
@import "./../../../node_modules/@foris/avocado-ui/lib/foris-avocado.css";
@import "./gridAvocadoUI";

// Avocado Monorepo
@import "./../../../node_modules/@foris/avocado-suite/node_modules/@foris/avocado-core/dist/style.css";
@import "./../../../node_modules/@foris/avocado-suite/dist/style.css";

// Temporary fix for the color variable until homologation starts
:root {
  --color-primary-50: #376197;
}

html {
  font-size: 1rem; //16px
}
body {
  height: calc(100vh - 200px);

  ::-webkit-inner-spin-button,
  ::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type="number"] {
    -moz-appearance: textfield;
  }

  input[type="time"] {
    -moz-appearance: textfield;
  }
}

.labelItem {
  @include text(12px, layout-grey-90, medium);
  margin: 0 0 4px;
}
.css-bgvzuu-indicatorSeparator {
  width: 0px !important;
}

.cnt_loader {
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.25);
  z-index: 10;
}

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 150ms ease-in-out;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}

.ReactModal__Content--after-open {
  overflow: visible !important;
}
