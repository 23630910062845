@import "../../../assets/scss/variables";
@import "../../../assets/scss/mixin";

.cntAutocomplete {
  @include box(foris-white, 0.25rem, layout-grey-30);
  padding: 0;
  position: relative;
  display: grid;
  grid-template-columns: auto 1fr;

  .cntSelect {
    position: relative;
    padding: 0.688rem 0.75rem;
    border-right: 1px solid map-get($colors, layout-grey-10);
    width: 116px;

    &_text {
      @include text(0.875rem, layout-grey-70);
      @include flex(space-between);
      line-height: 1rem;
      text-align: center;
      cursor: pointer;
      display: flex;
      align-items: center;
    }
    .listSelect {
      @include box(foris-white, 0.25rem, layout-grey-10);
      position: absolute;
      top: calc(100% + 4px);
      left: 0;
      right: 0;
      margin: 0;
      padding: 12px;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.14);
      list-style: none;
      z-index: map-get($zIndex, select);
      cursor: pointer;
      &_item {
        padding: 0 0 1rem;
        &:last-child {
          padding: 0;
        }
        .textSelect {
          @include text(0.75rem, layout-grey-90);
          margin: 0;
          &:hover {
            color: map-get($colors, darwined-blue-30);
          }
        }
      }
    }
  }
  &_input {
    @include flex(flex-start, center);
    flex: 1;
    position: relative;
    padding: 0 3.25rem 0 0.5rem;
    border: none;
    outline: none;
    &.cntAutocomplete_input__disabled {
      background: map-get($colors, layout-grey-5);
    }
    .inputAutocomplete {
      @include text(0.875rem, layout-grey-70);
      width: 100%;
      padding: 0;
      border: none;
      outline: none;
      &::placeholder {
        @include text(0.875rem, layout-grey-50);
      }
      &:disabled {
        background: map-get($colors, layout-grey-5);
      }
    }
    .listSuggest {
      @include box(foris-white, 0.25rem, layout-grey-10);
      position: absolute;
      top: calc(100% + 4px);
      left: 0;
      right: 0;
      margin: 0;
      padding: 1rem;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.14);
      list-style: none;
      z-index: map-get($zIndex, select);
      &_item {
        @include flex(space-between, center);
        padding: 0 0 1rem;
        cursor: pointer;
        &:last-child {
          padding: 0;
        }
        .textSuggest {
          @include text(0.875rem, layout-grey-90);
          flex: 1;
          margin: 0;
          &:hover {
            color: map-get($colors, darwined-blue-30);
          }
        }
      }
    }
  }
  &_btn {
    @include flex(center, center);
    width: 40px;
    position: absolute;
    top: -1px;
    bottom: -1px;
    right: -1px;
    background: map-get($colors, darwined-blue-10);
    border-top-right-radius: 0.1875rem;
    border-bottom-right-radius: 0.1875rem;
    border: none;
    outline: none;
    cursor: pointer;
    .iconSearch {
      color: map-get($colors, foris-white);
    }
  }
}
