@import "../../../../assets/scss/avocadoVariables";

.labelsWrapper {
  padding: map-get($spacing, md) map-get($spacing, xl) 0 map-get($spacing, xl);
}

.headerPills {
  display: flex;
  gap: 0.5rem;

  &_pill {
    height: 20px;
    gap: 0.25rem;
  }
}
