@import "../../../assets/scss/mixin";
@import "../../../assets/scss/variables";

.cntHeader {
  background: map-get($colors, darwined-blue-50);

  .dimensionWrapper {
    background: map-get($colors, darwined-blue-70);
    padding: 1rem 1.5rem;
    width: 100%;
    max-width: 172px;

    &_title {
      @include text(0.875rem, foris-white);
      margin: 0 0 0.25rem 0;
    }

    &_name {
      @include text(1.375rem, foris-white, medium);
    }
  }

  &_content {
    display: flex;
    width: 100%;

    &.cntHeader_content__divided {
      border-bottom: 1px solid map-get($colors, darwined-blue-30);
    }

    .menuWrapper {
      padding: 2rem;
    }
  }

  .headerIcon {
    width: 50px;
    margin: 0 1rem 0 0;
  }
  .infoItem {
    flex: 1;
    padding: 1rem;

    &_topTitle {
      @include text(0.875rem, foris-white);
      text-transform: uppercase;
      padding: 0 0 0.25rem;
      &_lower {
        text-transform: none;
      }
    }
    &_title {
      @include flex(flex-start, center);
      @include text(1.25rem, foris-white, bold);
      padding: 0 0 0.5rem 0;

      .icon {
        margin: 0 0 0 0.75rem;
      }
    }

    &_subTitleWrapper {
      display: flex;
      align-items: center;
      gap: 0.75rem;
    }

    &_subTitle {
      @include flex(flex-start, center);
      @include text(0.75rem, foris-white, medium);
      padding: 0.5rem 0 0;
      cursor: pointer;
      .regular {
        font-weight: map-get($fonts, regular);
        padding: 0 0 0 0.125rem;
      }
      .link {
        @include flex(flex-start, center);
        @include text(0.75rem, foris-white, medium);
        &.link__spacing {
          padding: 0 0 0.125rem 0.25rem;
        }
        &_text {
          text-decoration: underline;
        }
        &_icon {
          margin: 0 0 0.125rem 0.25rem;
          text-decoration: none;
        }
      }
    }
  }

  .detailsWrapper {
    width: 100%;
    padding: 0 0 1rem 0;
  }

  .linkTo {
    @include text(1rem, foris-white);
    border-top: 1px solid map-get($colors, darwined-blue-30);
    transition: background-color 0.25s;

    &:hover {
      background-color: map-get($colors, darwined-blue-70);
    }

    &_btn {
      padding: 0.75rem;
      background: none;
      cursor: pointer;
      @include text(0.875rem, foris-white, medium);
      @include flex(center, center);
      gap: 0.25rem;

      &.linkTo_btn__active {
        .icon {
          transform: rotate(180deg);
        }
      }
      .icon {
        color: map-get($colors, foris-white);
        transition: all 0.25s;
      }
    }
  }

  .headerSubtitle {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    &_label {
      color: map-get($colors, foris-white);
      font-size: 12px;
      font-weight: 500;
      line-height: 14px;

      .isRegular {
        font-weight: 400;
      }
    }
  }
}
