@import "./../../../assets/scss/variables";
@import "./../../../assets/scss/mixin";

.cnt_accordion {
  @include box(foris-white, 0.25rem, layout-grey-10);
  .cnt_item {
    align-items: center;
    padding: 0.5rem 1rem;
    position: relative;
    cursor: pointer;
    &.cnt_item--active {
      border-bottom: 1px solid map-get($colors, layout-grey-10);
    }
    &-btn {
      width: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      .btn_accordion {
        color: map-get($colors, layout-grey-30);
        transition: 0.5s ease;
        &.btn_accordion--active {
          transform: rotate(-180deg);
          transition: 0.5s ease;
        }
      }
    }
  }
  .content_accordion {
    display: none;
    transition: 0.5s ease;
    &.content_accordion--active {
      display: flex;
      transition: 0.5s ease;
    }
  }
}
