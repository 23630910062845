@import "../../../../../assets/scss/avocadoVariables";
@import "../../../../../assets/scss/mixin";
@import "../../../../../assets/scss/variables";

.cntEditTag {
  display: grid;
  grid-template-columns: 164px 1fr;
  gap: 1.5rem;
  align-items: flex-start;

  &:not(:last-of-type) {
    padding: 0 0 map-get($spacing, sm);
    margin: 0 0 map-get($spacing, sm);
    border-bottom: 1px solid map-get($colors, darwined-blue-30);
  }

  &.cntEditTag__addMargin {
    margin: 0.75rem 0 0;
  }

  &_actions {
    display: flex;
    flex-direction: column;
    text-align: left;
    gap: map-get($spacing, xs);

    .actionsTitle {
      @include text(0.875rem, foris-white, medium);
      display: flex;
    }

    .actionsLink {
      @include text(0.75rem, foris-white, medium);
      @include flex(flex-start, center);
      gap: 0.25rem;

      &:hover {
        color: map-get($colors, foris-white);
      }
    }
  }

  &_contentTags {
    gap: map-get($spacing, lg);
    display: flex;
    flex-direction: column;

    .tagCategory {
      &_name {
        display: block;
        font-size: map-get($sizeText, 12);
        font-weight: map-get($fonts, bold);
        color: map-get($colors, layout-grey-10);
        margin: 0 0 map-get($spacing, xs);
      }

      &_labels {
        display: flex;
        flex-wrap: wrap;
        gap: map-get($spacing, xs);
      }
    }

    .tagGroup {
      &.tagGroup__system {
        .tagGroup_name {
          &::before {
            display: none;
          }
        }
      }

      &_code {
        font-weight: map-get($fonts, extraBold);
        text-transform: uppercase;
      }
      &_name {
        &::before {
          content: "|";
          padding: 0 0.25rem;
          font-weight: map-get($fonts, extraBold);
        }
      }
    }
  }
}
