@import "../../../assets/scss/mixin";
@import "../../../assets/scss/variables";

.headText {
  font-weight: 500;
  color: map-get($colors, layout-grey-90);
}

.codeText {
  font-weight: 500;
}

.columnLink,
.columnCell {
  word-break: break-word;
}

.columnLink {
  color: map-get($colors, darwined-blue-50);
  padding: 0 0.5rem 0 0;
  display: block;
}

.columnCell {
  color: map-get($colors, layout-grey-90);
  padding: 0 0.25rem 0 0;
  display: block;
}
