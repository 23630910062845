@import "../../../../assets/scss/mixin";
@import "../../../../assets/scss/variables";

.editLabels {
  margin: 0;
  padding: 2rem;
  &_title {
    @include text(1.5rem, layout-grey-90, bold);
    padding: 0 0 1.5rem;
  }
  .cntLabels {
    padding: 1.5rem 0 0;
    .cntLabelsTitle {
      padding: 0 0 1rem;
      display: flex;
      align-items: center;

      &_title {
        @include text(1rem, layout-grey-70, bold);
      }
      &_delete {
        @include text(0.875rem, message-negative-30);
        padding: 0.25rem 0.75rem;
        min-height: unset;
        display: flex;
        margin: 0 0 0 0.25rem;

        .icon {
          @include flex(center, center);
          width: 16px;
          height: 16px;
          margin: 0 0.5rem 0 0;
          border: 1px solid map-get($colors, message-negative-30);
          border-radius: 50%;
          color: map-get($colors, message-negative-30);
        }
      }
    }
    .cntLabelsTagItem {
      margin: 0 0.75rem 0 0;
      &:last-of-type {
        margin: 0;
      }
      &.cntLabelsTagItem__new,
      .cntLabelsTagButton__new {
        background: map-get($colors, message-alert-50);
      }
      &_code {
        @include text(0.875rem, foris-white);
      }
      &_code {
        @include text(0.875rem, foris-white, bold);
        text-transform: uppercase;
      }
    }
  }
  .footer {
    margin: 1.5rem 0 0;
    padding: 1.5rem 0 0;
    border-top: 1px solid map-get($colors, layout-grey-30);
    &_btn {
      &:first-of-type {
        margin: 0;
      }
      margin: 0 0 0 1.5rem;
    }
  }
}

.bold {
  font-weight: map-get($fonts, medium);
}
