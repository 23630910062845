@import "./../../../../assets/scss/variables";
@import "./../../../../assets/scss/mixin";

.linkCell {
  @include text(0.875rem, darwined-blue-10);
  &_bold {
    font-weight: map-get($fonts, bold);
  }
  &_regular {
    .bold {
      font-weight: map-get($fonts, bold);
    }
  }
}
.iconDisabledCell {
  color: map-get($colors, layout-grey-30);
}
