.loader {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  margin: 0;
  text-align: center;
  z-index: 1000;
  transform: translateX(-50%) translateY(-50%);
  width: 2.28571429rem;
  height: 2.28571429rem;
  font-size: 1em;

  &::before {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 500rem;
    border: 0.2em solid rgba(0, 0, 0, 0.1);
  }

  &::after {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    -webkit-animation: loader 0.6s linear;
    animation: loader 0.6s linear;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    border-radius: 500rem;
    border-color: #767676 transparent transparent;
    border-style: solid;
    border-width: 0.2em;
    box-shadow: 0 0 0 1px transparent;
  }

  &.loader__inline {
    display: inline-block;
  }

  &.loader__mini {
    width: 1rem;
    height: 1rem;
    font-size: 0.78571429em;
  }

  &.loader__tiny {
    width: 1.14285714rem;
    height: 1.14285714rem;
    font-size: 0.85714286em;
  }

  &.loader__small {
    width: 1.71428571rem;
    height: 1.71428571rem;
    font-size: 0.92857143em;
  }

  &.loader__large {
    width: 3.42857143rem;
    height: 3.42857143rem;
    font-size: 1.14285714em;
  }

  &.loader__big {
    width: 3.71428571rem;
    height: 3.71428571rem;
    font-size: 1.28571429em;
  }

  &.loader__huge {
    width: 4.14285714rem;
    height: 4.14285714rem;
    font-size: 1.42857143em;
  }

  &.loader__massive {
    width: 4.57142857rem;
    height: 4.57142857rem;
    font-size: 1.71428571em;
  }
}

@-webkit-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
