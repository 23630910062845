@import "../../assets/scss/avocadoVariables";
@import "../../assets/scss/mixin";

.layout {
  background: map-get($colors, layout-white);
  min-height: 100vh;

  .header {
    height: 50px;
    position: relative;
    background: map-get($colors, foris-white);
    box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.1);
    z-index: 1;

    .cntImage {
      @include flex(center, center);
      padding: 0 map-get($spacing, sm) 0 map-get($spacing, md);
      border-left: 1px solid map-get($colors, layout-grey-10);
      cursor: pointer;

      .img {
        flex-shrink: 0;
        margin: 0;
      }
    }

    &_moduleName {
      position: relative;
      padding: 1.125rem map-get($spacing, sm) map-get($spacing, sm) map-get($spacing, xs);
      color: #8c93a6;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      width: auto;

      &::before {
        content: "";
        position: absolute;
        width: 1px;
        height: 24px;
        top: map-get($spacing, md);
        left: 0;
        background-color: #cbd5e1;
      }
    }

    .cntInfo {
      @include flex(flex-start, center);
      flex: 1;
      margin: 0 auto 0 1.25rem;

      &_autocomplete {
        width: 100%;
        max-width: 400px;
      }
    }
    .products {
      @include flex(center, center);
      min-width: 50px;
      border-left: 1px solid map-get($colors, layout-grey-10);
      border-right: 1px solid map-get($colors, layout-grey-10);
      cursor: pointer;
    }
  }
}
