@import "../../../../assets/scss/mixin";
@import "../../../../assets/scss/variables";

.cntDetails {
  margin: 0;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 0;
  opacity: 0;
  transition: all 0.3s ease;
  &.cntDetails__active {
    height: auto;
    margin: 1.5rem 0 0;
    opacity: 1;
    overflow: visible;
  }

  &_content {
    border-bottom: 1px solid map-get($colors, darwined-blue-30);
    padding: 0 0 1rem 0;
  }
}
