@import "../../../../assets/scss/mixin";
@import "../../../../assets/scss/variables";

.cntChangeComponent {
  width: 100%;
  height: 100%;
  min-height: calc(100vh - 133px);
  min-width: 100vw;
  position: absolute;
  top: 50px;
  bottom: 0;
  background: map-get($colors, foris-white);
  z-index: map-get($zIndex, modal);
  .content {
    padding: 2rem 2.5rem 0;
    &_title {
      @include text(16px, layout-grey-90, medium);
      margin: 0;
      padding: 0 0 1rem;
    }
  }
  .cntFooter {
    padding: 1rem 2.5rem 2rem;
    &_btn {
      margin: 0 0 0 0.5rem;
      &:first-of-type {
        margin: 0;
      }
    }
  }
}
