@import "../../../assets/scss/variables";
@import "../../../assets/scss/mixin";

.list_validation {
  list-style: none;
  padding: 1.5rem 0 0;
  &_item {
    @include text(0.875rem, message-alert-50);
    padding: 0.25rem 0 0;
    &:first-of-type {
      padding: 0;
    }
    &.list_validation_item__error {
      @include text(14px, message-negative-50);
    }
  }
}
