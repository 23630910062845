@import "../../../assets/scss/variables";
@import "../../../assets/scss/mixin";

.cntEmpty {
  padding: 1.875rem;
  &_title {
    @include text(1.5rem, layout-grey-90, medium);
  }
  &_text {
    @include text(1.125rem, layout-grey-90, medium);
  }
  .cntBtn {
    margin: 2rem 0 0;
    &_item {
      width: 200px;
      padding: 0.625rem 0.75rem;
      cursor: pointer;
      outline: none;
      &__home {
        @include box(foris-white, 0.25rem, darwined-blue-30);
        @include text(0.875rem, darwined-blue-30, bold);
      }
      &__search {
        @include box(darwined-blue-30, 0.25rem);
        @include text(0.875rem, foris-white, bold);
        margin: 0 0 0 1rem;
      }
    }
  }
}
